<template>
    <div class="bg-6-trans text-black-50 pt-lg-6 pt-3 pb-4 row">
        <div class="col-md-4 col-12" v-if="imageAlign === 'left'">
            <image-with-shadow :image="image" class="abs-top"></image-with-shadow>
        </div>
        <div class="item-container col-md-6 col-12 order-1 order-lg-0">
            <div class="p-3 ml-lg-5 mt-5 mt-lg-0">
                <div class="fl-x">
                    <i :class="icon" class="mb-2 color-primary text-2" v-if="icon !==''"></i>
                    <span><h5 class="montserrat-medium font-weight-bold">{{ heading }}</h5></span>
                </div>
                <div class="fl-x fl-a-s mb-4">
                    <i class="mb-2 color-primary fa fa-envelope text-2" v-if="icon !==''"></i>
                    <span class="text-secondary"><h5 class="montserrat-medium fs-1 m-0">{{ role }}</h5></span>
                </div>
                <p class="text-left fs-1 text-3">
                    <slot name="content"></slot>
                </p>

                <div class="social-media text-2 mt-lg-5 mt-4">
                    <a v-for="(i, index) in siteMap" :key="index" :href="i.website_link"
                       class="social-icon border-r-1 p-2">
                        <img v-if="i.site_map_type==='Facebook'"
                             src="../../assets/web/social-media-icons/fb-dark.png" alt=""
                             class="img-fluid social-item"/>
                        <img v-if="i.site_map_type==='Instagram'" height="40" width="40"
                             src="../../assets/web/social-media-icons/insta-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='LinkedIn'" height="40" width="40"
                             src="../../assets/web/social-media-icons/linkedin-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Pinterest'" height="40" width="40"
                             src="../../assets/web/social-media-icons/pinterest-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='QQ'" height="40" width="40"
                             src="../../assets/web/social-media-icons/qq-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Quora'" height="40" width="40"
                             src="../../assets/web/social-media-icons/quora-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Reddit'" height="40" width="40"
                             src="../../assets/web/social-media-icons/reddit-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Sina Weibo'" height="40" width="40"
                             src="../../assets/web/social-media-icons/Sina_Weibo-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Skype'" height="40" width="40"
                             src="../../assets/web/social-media-icons/skype-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='SnapChat'" height="40" width="40"
                             src="../../assets/web/social-media-icons/Snapchat-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Microsoft Teams'" height="40" width="40"
                             src="../../assets/web/social-media-icons/teams-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Telegram'" height="40" width="40"
                             src="../../assets/web/social-media-icons/telegram-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Twitter'" height="40" width="40"
                             src="../../assets/web/social-media-icons/twitter-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='Youtube'" height="40" width="40"
                             src="../../assets/web/social-media-icons/utube-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='WeChat'" height="40" width="40"
                             src="../../assets/web/social-media-icons/Wechat-dark.png" alt=""
                             class="img-fluid"/>
                        <img v-if="i.site_map_type==='WhatsApp'" height="40" width="40"
                             src="../../assets/web/social-media-icons/whatsapp-dark.png" alt=""
                             class="img-fluid"/>
                    </a>
                </div>

            </div>
        </div>
        <div class="col-md-4 col-12 order-0 order-lg-1" v-if="imageAlign === 'right'">
            <image-with-shadow :image="image" class="abs-top"></image-with-shadow>
        </div>
    </div>
</template>
<script> import ImageWithShadow from '@components/ImageWithShadow';

export default {
    name: 'MemberLargeCard',
    components: { ImageWithShadow },
    props: {
        siteMap: { type: Array, default: null },
        icon: { type: String, default: '' },
        imageAlign: { type: String, default: 'left' },
        image: { type: Image, default: null },
        heading: { type: String, default: 'Heading' },
        role: { type: String, default: 'Heading' },
        content: { type: String, default: 'Contents here' }
    }
};
</script>
<style lang="scss" scoped>
.b-shadow {
    border-radius: 20px; /*background: var(--color-secondary-700);*/
    box-shadow: 5px 5px 16px #2a2a2a, -5px -5px 16px var(--color-secondary-700);
}

.icon-img {
    width: 1.375rem;
    height: auto;
}

.abs-top {
    @media (min-width: 992px) {
        position: absolute;
        top: -8rem;
    }

}

.m-h-md {
    @media (min-width: 992px) {
        min-height: 16.8125rem;
    }
}

.item-container {
    text-align: center;
    @media (min-width: 992px) {
        text-align: left;
    }
}
</style>
